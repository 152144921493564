<template>
  <b-card-code title="Un Settle Invoice List">
    <div class="custom-search">
      <b-col
        cols="12"
        md="12"
        xl="12"
      >

        <b-row>
          <b-col md="4">
            <b-form-group>
              <div v-show="isShowMakeSattlementBtn">
                <b-button
                  v-b-toggle.settlement-sidebar-add1
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="mb-75"
                  shadow
                  right
                >
                  Make New Settlement
                </b-button>
              </div>

            </b-form-group>
          </b-col>
          <b-col md="8">
            <b-badge class="badge-success">
              Total Amount:{{ this.gateway_paid }} TK
            </b-badge>
                        &nbsp;
            <b-badge class="badge-warning">
              Total Charge:{{ this.gateway_charge }} TK
            </b-badge>
                        &nbsp;
            <b-badge class="badge-success">
              Total Receivable Amount: {{ this.receivable_amount }} TK
            </b-badge>
          </b-col>

        </b-row>
      </b-col>

      <b-sidebar
        id="settlement-sidebar-add1"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        right
        backdrop
        shadow
      >
        <!-- #default="{ hide }" -->
        <template
          v-show="isShow"
          #default="{ hide }"
        >
          <!-- Header -->
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              content-sidebar-header
              px-2
              py-1
            "
          >
            <h5 class="mb-0">
              Add New Settlement
            </h5>
          </div>
          <validation-observer ref="simpleRules">
            <!-- Body -->
            <b-form class="p-2">
              <b-form-group
                label="Bank Transaction Number"
                label-for="bank_trans_id"
              >
                <b-form-input
                  id="bank_trans_id"
                  v-model="bank_trans_id"
                  trim
                />
              </b-form-group>
              <b-form-group
                label="Remark"
                label-for="remakr"
              >
                <b-form-textarea
                  id="remakr"
                  v-model="remakr"
                  placeholder="Enter something..."
                  rows="3"
                  max-rows="6"
                />
              </b-form-group>
              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                  @click.prevent="validationForm"
                  @click="hide"
                >
                  Send
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="hide"
                >
                  Cancel
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </template>
      </b-sidebar>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">

      <b-form-group class=" ">
        <div class="d-flex align-items-center ">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>

    </div>
    <!-- table -->
    <vue-good-table
      :is-loading.sync="isLoading"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      theme="my-theme"
      @on-page-change="onPageChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      @on-select-all="onSelectAll"
      @on-row-click="onRowClick"
    >
      <!-- Column: Id -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <div v-if="props.column.field === 'paid_date'">
          {{ props.row.paid_date }}
        </div>
        <div v-if="props.column.field === 'invoice_code'">
          <b-link
            :to="{
              name: 'isp-invoice-preview',
              params: { invoiceId: props.row.id },
            }"
            class="font-weight-bold"
          >
            {{ props.row.invoice_code }}
          </b-link>
        </div>
        <div v-if="props.column.field === 'user'">
          <span class="font-weight-bold d-block text-nowrap">
            {{ props.row.isp_user_name ? props.row.isp_user_name : null }}
          </span>
        </div>
        <div v-if="props.column.field === 'pending_amount'">
          {{ props.row.pending_amount }}
        </div>
        <div v-if="props.column.field === 'charge_amount'">
          {{ props.row.charge_amount }}
        </div>
        <div v-if="props.column.field === 'total_payable_amount'">
          {{ props.row.total_payable_amount }}
        </div>
      </template>
      <!-- Column: payment_status Date -->

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="['100', '300', '500']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> of {{ totalRow }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="totalRow"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BLink,
  BTooltip,
  BMedia,
  BBadge,
  BButton,
  VBToggle,
  BSidebar,
  BForm,
  BFormTextarea,
} from 'bootstrap-vue'
import { ISP_LIST, INVOICE_LIST, INVOICE_SHOW } from '@core/services/api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
// import store from '@/store/index'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'
import { required, email } from '@validations'
import { checkRes } from '@core/services/helper'

import router from '@/router'

// Use toast
import Mixin from '@core/services/mixin'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BLink,
    BTooltip,
    BMedia,
    BBadge,
    BButton,
    BSidebar,
    BForm,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  mixins: [Mixin],
  data() {
    const resolveClientAvatarVariant = status => {
      if (status === 'Partial Payment') return 'primary'
      if (status === 'Paid') return 'danger'
      if (status === 'Downloaded') return 'secondary'
      if (status === 'Draft') return 'warning'
      if (status === 'Sent') return 'info'
      if (status === 'Past Due') return 'success'
      return 'primary'
    }
    return {
      isLoading: false,
      totalRow: 0,
      toggle: false,
      isShow: false,
      isShowMakeSattlementBtn: false,
      pageLength: 100,
      dir: false,
      required,
      columns: [
        {
          field: 'paid_date',
          label: 'Payment Date & Time',
          sortable: true,
        },
        {
          field: 'user',
          label: 'Username',
        },
        {
          field: 'invoice_code',
          label: 'Invoice Number',
          // sortable: true,
        },
        {
          field: 'pending_amount',
          label: 'Amount',
          // sortable: true,
          formatter: val => `${val} BDT`,
        },
        {
          field: 'charge_amount',
          label: 'Fees',
          // sortable: true,
          formatter: val => `${val} BDT`,
        }, {
          field: 'total_payable_amount',
          label: 'Receivable',
          // sortable: true,
          formatter: val => `${val} BDT`,
        },
      ],
      rows: [],
      invoice_ids: [],
      isp_list: [],
      isp_id: router.currentRoute.params.ispId,
      searchTerm: '',
      from_date: '',
      to_date: '',
      bank_trans_id: null,
      remakr: null,
      gateway_charge: 0,
      gateway_paid: 0,
      receivable_amount: 0,
      serverParams: {
        columnFilters: {},
        page: 1,
        perPage: 100,
      },
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    this.idpListData()
    this.searchInvoiceSettlement()
  },
  methods: {
    searchInvoiceSettlement() {
      this.$http
        .get(process.env.VUE_APP_BASEURL + INVOICE_LIST, {
          params: {
            isp_id: this.isp_id,
            payment_status: 1, // paid
            payment_method: 2, // Gateway
            is_settlement: 0, // settlement pending,
            is_pagination: true,
            sortBy: 'paid_date',
            sortDesc: true,
            perPage: this.serverParams.perPage,
            page: this.serverParams.page,
          },
        })
        .then(res => {
          this.rows = res.data.data.invoices.data
          this.totalRow = res.data.data.total

          // const gatewayPaid = this.rows.reduce((acc, val) => acc + val.pending_amount, 0);
          // this.gateway_paid = gatewayPaid.toFixed(2);
          //
          // const gatewayCharge =(res.data.data.isp_info.gateway_processing_free/100) * this.gateway_paid;
          // this.gateway_charge = gatewayCharge.toFixed(2);
          //
          // const receivable_amount = this.gateway_paid - this.gateway_charge;
          // this.receivable_amount = receivable_amount.toFixed(2);

          if (this.rows.length > 0) {
            this.isShowMakeSattlementBtn = true
          }
        })
    },
    onRowClick(params) {
      console.log('onRowClick params select or unselect=>', params)
      console.log('onRowClick', params.selected)
      if (params.selected == true) {
        this.gateway_paid += params.row.amount
      } else if (params.selected == false) {
        this.gateway_paid -= params.row.amount
      }

      const gatewayPaid = this.gateway_paid.toFixed(2)
      this.gateway_charge = (params.row.isp_company_info.gateway_processing_free / 100) * gatewayPaid
      const gatewayCharge = this.gateway_charge
      this.receivable_amount = (gatewayPaid - gatewayCharge)
      if (!params.selected && this.invoice_ids.includes(params.row.id)) {
        this.invoice_ids.splice(this.invoice_ids.indexOf(params.row.id), 1)
      } else {
        this.invoice_ids.push(params.row.id)
      }
      console.log('onRowClick this.invoice_ids=>', this.invoice_ids)
      // this.$toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: `Hello user! You have clicked on row ${params.row.id}`,
      //     icon: 'UserIcon',
      //     variant: 'success',
      //   },
      // })
    },
    onSelectAll(params) {
      console.log('onSelectAll params=>', params)
      this.gateway_paid = 0
      this.gateway_charge = 0
      this.receivable_amount = 0
      if (params.selected) {
        params.selectedRows.forEach((item, index) => {
          this.gateway_paid += item.amount
          const gatewayPaid = this.gateway_paid.toFixed(2)
          const gatewayCharge = (item.isp_company_info.gateway_processing_free / 100) * gatewayPaid
          this.gateway_charge = gatewayCharge.toFixed(2)
          this.receivable_amount = (gatewayPaid - this.gateway_charge)
          this.invoice_ids[index] = item.id
        })
      } else {
        this.invoice_ids = []
        this.gateway_paid = 0
        this.gateway_charge = 0
        this.receivable_amount = 0
      }
      console.log('onSelectAll', this.invoice_ids)
    },
    idpListData() {
      this.$http
        .get(process.env.VUE_APP_BASEURL + ISP_LIST)
        .then(res => {
          this.isp_list = res.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            userData.value = undefined
          }
        })
    },
    validationForm() {
      this.$refs.simpleRules.validate()
        .then(success => {
          if (success) {
            useJwt
              .newSettlementAdd({
                invoice_id: this.invoice_ids ?? null,
                isp_id: this.isp_id ?? null,
                settlement_from: this.from_date ?? null,
                settlement_to: this.to_date ?? null,
                bank_trans_id: this.bank_trans_id ?? null,
                remark: this.remakr ?? null,
              })
              .then(response => {
                if (checkRes(response.status) && response.data.data) {
                  this.isShow = false
                  this.$router
                    .push({ name: 'admin-isp-settlement-list' })
                    .catch(success => {
                      this.toastMessage('success', 'Invoice Payment', response)
                    })
                } else {
                  this.$router
                    .push({ name: 'admin-new-settlement' })
                    .then(() => {
                      this.toastMessage('warning', 'Invoice Payment', response)
                    })
                    .catch(error => {
                      this.toastMessage('danger', 'Invoice Payment', response)
                    })
                }
              })
          }
        })
    },

    // for pagination
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.searchInvoiceSettlement()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.searchInvoiceSettlement()
    },

    // onSortChange(params) {
    //     this.updateParams({
    //         sort: [{
    //             type: params.sortType,
    //             field: this.columns[params.columnIndex].field,
    //         }],
    //     });
    //     this.searchInvoiceSettlement();
    // },

    onColumnFilter(params) {
      console.log('onColumnFilter', params)
      this.updateParams(params)
      this.searchInvoiceSettlement()
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
